import React, { Fragment } from "react";

import useStyles from "../styles";
import Typography from "@ui-kit/Typography";
import CheckIcon from "@material-ui/icons/Check";
import { Progress } from "@ui-kit/Progress";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Spacer } from "@ui-kit/Spacer";
import { ShadowDivider } from "@components/ShadowDivider";
import Button from "@ui-kit/Button";

interface ClaimSuccessStepProps {
  showSchoolResponseSuccess: boolean;
  onDone: () => void;
}

export const ClaimSuccessStep: React.FC<ClaimSuccessStepProps> = ({
  showSchoolResponseSuccess,
  onDone,
}) => {
  const [{ facility }] = useDeepDivePanelStore();
  const classes = useStyles();

  return (
    <Fragment>
      {showSchoolResponseSuccess && (
        <div className={classes.successWrapper}>
          <div className="icon">
            <CheckIcon />
          </div>
          <Typography bolded>Response sent</Typography>
        </div>
      )}

      <Spacer />

      <Typography paragraph bolded align="center">
        School profile completeness
      </Typography>

      <Progress value={facility.profileCompleteness.percent / 100} />

      <ShadowDivider />

      <Spacer />

      <Button
        variant="contained"
        color="primary"
        name="done"
        size="large"
        onClick={onDone}
        fullWidth
      >
        Done
      </Button>
    </Fragment>
  );
};
