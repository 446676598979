import React from "react";

import { EditFeaturesAndAmenitiesView } from "@pages/DeepDivePanel/EditFeaturesAndAmenities/view";
import { Models } from "@services/api";
import { ClaimStep } from "../ClaimStep";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  onPrev: () => void;
  onNext: () => void;
}

export const FeaturesAndAmenitiesStep: React.FC<IProps> = ({
  facilityView,
  setFacilityView,
  onPrev,
  onNext,
}) => {
  return (
    <ClaimStep
      title="Features & amenities"
      setFacilityView={setFacilityView}
      facilityView={facilityView}
      onNext={onNext}
      onPrev={onPrev}
    >
      {(view, setView) => (
        <EditFeaturesAndAmenitiesView
          facilityView={view}
          setFacilityView={setView}
          isClaim
        />
      )}
    </ClaimStep>
  );
};
