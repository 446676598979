import React, { Fragment, useEffect } from "react";
import track, { useTracking } from "react-tracking";

import Dialog from "@ui-kit/Dialog";
import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { useCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Models } from "@services/api";
import { Spacer } from "@ui-kit/Spacer";
import { DataMissingDialogProps } from "./types";
import { Progress } from "@ui-kit/Progress";
import { ShadowDivider } from "@components/ShadowDivider";
import { useEnrollmentResponseStore } from "@store/EnrollmentResponseStore";

const Component: React.FC<DataMissingDialogProps> = ({ onNext, onClose }) => {
  const { trackEvent } = useTracking();
  const [{ inquiryId }] = useEnrollmentResponseStore();
  const [{ facility }] = useDeepDivePanelStore();
  const css = useCss();

  useEffect(() => {
    trackEvent({
      action: "Page Loaded",
    });
  }, []);

  const close = () => {
    trackEvent({
      action: "Close Page",
    });
    onClose();
  };

  return (
    <Dialog
      open
      onClose={close}
      data-test="data-missing-dialog"
      title="Missing school data"
      tier={facility.subscriptionTier}
    >
      <div css={css.root}>
        <Typography paragraph bolded align="center">
          School profile completeness
        </Typography>

        <Progress value={facility.profileCompleteness.percent / 100} />

        {facility.profileCompleteness.percent !== 100 && (
          <Fragment>
            <Spacer size="medium" />

            {!!inquiryId ? (
              <Fragment>
                <Typography paragraph bolded align="center">
                  Missing information detected
                </Typography>
                <Typography paragraph>
                  Looks like you're missing a few key details about your school.
                </Typography>
                <Typography paragraph>
                  To respond to parent inquiries, please review your profile for
                  completeness. Your pending response will be automatically sent
                  once this is done.
                </Typography>
              </Fragment>
            ) : (
              <Typography paragraph>
                Looks like you're missing a few key details about your school.
                Please review your profile for completeness.
              </Typography>
            )}

            <Typography gap>
              Mandatory items are marked with a{" "}
              <span css={{ color: "red" }}>*</span>
            </Typography>

            <Button
              variant="contained"
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Ivy
                  ? "yellow"
                  : "black"
              }
              fullWidth
              size="large"
              name="complete"
              onClick={onNext}
            >
              Complete profile
            </Button>
          </Fragment>
        )}

        {facility.profileCompleteness.percent === 100 && (
          <Fragment>
            <ShadowDivider />

            <Button
              variant="contained"
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Ivy
                  ? "yellow"
                  : "black"
              }
              fullWidth
              size="large"
              name="done"
              link={`/map/${facility.id}`}
            >
              Done
            </Button>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};

export const DataMissingDialog = track({ page: "Facility Data Missing" })(
  Component,
);
