import React from "react";

import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";
import { format_percent } from "@helpers/format_number";
import { ProgressProps } from "./types";

export const Progress: React.FC<ProgressProps> = ({ value }) => {
  const css = createCss();

  return (
    <div css={css.progressContainer}>
      <div css={css.progressTrack}>
        <div
          css={[
            css.progress,
            {
              width: format_percent(value),
            },
          ]}
        />
      </div>

      <Typography
        css={{
          marginLeft: 15,
        }}
        bolded
      >
        {format_percent(value)}
      </Typography>
    </div>
  );
};
