import React from "react";

import {
  EditVirtualTourView,
  processServerErrors,
} from "@pages/DeepDivePanel/EditVirtualTour/view";
import { Models } from "@services/api";
import { ClaimStep } from "../ClaimStep";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  onPrev: () => void;
  onNext: () => void;
}

export const VirtualTourStep: React.FC<IProps> = (props) => {
  return (
    <ClaimStep
      {...props}
      title="Virtual tour"
      processErrors={[processServerErrors]}
    >
      {(view, setView) => (
        <EditVirtualTourView
          facilityView={view}
          setFacilityView={setView}
          isClaim
        />
      )}
    </ClaimStep>
  );
};
