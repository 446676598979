import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  progressContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    width: "90%",
  },

  progressTrack: (theme) => ({
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 7,
    flex: 1,
    height: 25,
  }),

  progress: (theme) => ({
    backgroundColor: theme.palette.success.main,
    borderRadius: 7,
    flex: 1,
    height: "100%",
  }),
});
