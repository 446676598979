import React from "react";

import EditTuitionAndFees, {
  processServerErrors,
} from "@pages/DeepDivePanel/EditTuitionAndFees/view";
import { Models } from "@services/api";
import { ClaimStep } from "../ClaimStep";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  onPrev: () => void;
  onNext: () => void;
}

export const TuitionAndFeesStep: React.FC<IProps> = (props) => {
  return (
    <ClaimStep
      {...props}
      title="Tuition & fees"
      processErrors={processServerErrors}
    >
      {(view, setView) => (
        <EditTuitionAndFees
          facilityView={view}
          setFacilityView={setView}
          isClaim
        />
      )}
    </ClaimStep>
  );
};
