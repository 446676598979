import React, { useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import isNumber from "lodash/isNumber";
import isFunction from "lodash/isFunction";
import track, { useTracking } from "react-tracking";

import Dialog from "@ui-kit/Dialog";
import useStyles from "./styles";
import { ROUTES } from "@constants";
import { EditFacilityContactInfoState } from "@components/EditFacilityInfoForm/types";
import { useDeepDivePanelStore, usePrevious } from "@hooks";
import { useUserStore } from "@store/UserStore";
import { ReactGAEventWait } from "@helpers/ga";
import { Models } from "@services/api";
import { ContactInfoStep } from "./ContactInfoStep";
import { OperationalDetailsStep } from "./OperationalDetailsStep";
import { FeaturesAndAmenitiesStep } from "./FeaturesAndAmenitiesStep";
import { TuitionAndFeesStep } from "./TuitionAndFeesStep";
import { InitialStep } from "./InitialStep";
import { ClaimSuccessStep } from "./SuccessStep";
import { DocumentsStep } from "./DocumentsStep";
import { CloseWarning } from "./CloseWarning";
import { MealsStep } from "./MealsStep";
import { OverviewStep } from "./OverviewStep";
import { VirtualTourStep } from "./VirtualTourStep";
import { ProgramsAndServicesStep } from "./ProgramsAndServicesStep";
import { useEnrollmentResponseStore } from "@store/EnrollmentResponseStore";
import { useNotificationStore } from "@store/NotificationStore";
import { DataMissingDialog } from "@pages/DeepDivePanel/DataMissingDialog";
import { AxiosError } from "axios";
import { ServerError } from "@models/common";

enum ClaimStep {
  HomeScreen = "homeScreen",
  ContactInfo = "contactInfo",
  OperatinalInfo = "operationalInfo",
  TuitionInfo = "tuitionInfo",
  DocumentsInfo = "documentsInfo",
  FeaturesInfo = "featuresInfo",
  OverviewInfo = "overview",
  Success = "success",
  MealsInfo = "mealsInfo",
  VirtualToursInfo = "virtualToursInfo",
  ProgramsInfo = "programsInfo",
}

const ClaimForm: React.FC = () => {
  const { trackEvent } = useTracking();
  const history = useHistory();
  const { search } = useLocation();
  const query = qs.parse(search);
  const [{ user }] = useUserStore();
  const prevUser = usePrevious(user);
  const [, { setNotification }] = useNotificationStore();
  const [{ afterClaimCallback, inquiryId }, { resetAfterClaimCallback }] =
    useEnrollmentResponseStore();
  const [{ facility }, { saveFacility }] = useDeepDivePanelStore();
  const [editContactsState, setEditContactsState] =
    useState<EditFacilityContactInfoState>(() => ({
      editDesignations: false,
      editDistrict: false,
      editEmail: false,
      editLanguages: false,
      editName: false,
      editOperatingHours: !isNumber(facility.schedule[0]?.start),
      editPhone: !facility.phone,
      editStatus: false,
    }));

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [allowFlow, setAllowFlow] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showCloseWarning, setShowCloseWarning] = useState(false);
  const [facilityView, setFacilityView] = useState(cloneDeep(facility));
  const [showSchoolResponseSuccess, setShowSchoolResponseSuccess] =
    useState(false);
  const [role, setRole] = useState(user?.role || Models.UserRole.Owner);

  const classes = useStyles();

  useEffect(() => {
    if (!prevUser && user) {
      setAllowFlow(true);
    }
  }, [user]);

  useEffect(() => {
    trackEvent({
      action: "Page Loaded",
    });
    history.replace({
      search: qs.stringify({
        ...query,
        step: ClaimStep.HomeScreen,
      }),
    });
  }, []);

  useEffect(() => {
    setFacilityView(cloneDeep(facility));
  }, [facility]);

  useEffect(() => {
    const content = document.getElementsByClassName("dialog-content")[0];

    if (content && content.scrollTo) {
      content.scrollTo(0, 0);
    }
  }, [query.step]);

  useEffect(() => {
    if (shouldSubmit) {
      void submit();
    }
  }, [shouldSubmit]);

  if (user && facility.accountId && user.account.id !== facility.accountId) {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
    return null;
  }

  if (!allowFlow) {
    return (
      <DataMissingDialog
        onNext={() => {
          if (!facility.accountId) {
            history.push({
              pathname: `/map/${facility.id}/claim`,
              search,
            });
            return;
          }

          checkAuthorization();
        }}
        onClose={() => {
          if (isFunction(afterClaimCallback)) {
            history.push({
              pathname: `/map/${facility.id}/service-inquiry/${inquiryId}/response`,
              search,
            });
          } else {
            history.replace({
              pathname: `/map/${facility.id}`,
              search,
            });
          }
        }}
      />
    );
  }

  const setStep = (s: ClaimStep) => {
    trackEvent({
      action: "Step Change",
      step: s,
    });
    history.push({
      search: qs.stringify({
        ...query,
        step: s,
      }),
    });
  };

  function checkAuthorization() {
    if (!user) {
      history.replace(
        `${ROUTES.LOGIN_FORM}?joinedFrom=${Models.UserJoinedFrom.EditFacilityProfile}`,
      );
    } else {
      setAllowFlow(true);
    }
  }

  const submit = async () => {
    try {
      ReactGAEventWait({
        action: "Submit",
        category: "FacilityClaim",
      });

      setFetching(true);

      await saveFacility(facilityView);

      if (isFunction(afterClaimCallback)) {
        await afterClaimCallback();
        setShowSchoolResponseSuccess(true);
      }

      trackEvent({
        action: "Submitted Successfully",
        facilityId: facility.id,
      });
      ReactGAEventWait({
        action: "SubmitSuccess",
        category: "FacilityClaim",
      });
      setStep(ClaimStep.Success);
    } catch (e) {
      ReactGAEventWait({
        action: "SubmitError",
        category: "FacilityClaim",
      });

      const error: AxiosError<{ errors: ServerError[] }> = e;
      const errors = error?.response?.data?.errors;

      if (errors) {
        setNotification({
          message: errors[0].title,
          type: "error",
        });
      } else {
        throw error;
      }
    } finally {
      setShouldSubmit(false);
      setFetching(false);
    }
  };

  function closeModal() {
    if (query.step === ClaimStep.Success) {
      resetAfterClaimCallback();
      history.push({
        pathname: `/map/${facility.id}`,
        search,
      });
    } else {
      setShowCloseWarning(true);
    }
  }

  const getStepView = () => {
    switch (query.step) {
      case ClaimStep.HomeScreen:
        return (
          <InitialStep
            onNext={() => setStep(ClaimStep.ContactInfo)}
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            setRole={setRole}
            defaultRole={role}
          />
        );
      case ClaimStep.ContactInfo:
        return (
          <ContactInfoStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setStep(ClaimStep.OperatinalInfo)}
            onPrev={() => history.goBack()}
            editState={editContactsState}
            onEditState={setEditContactsState}
          />
        );
      case ClaimStep.OperatinalInfo:
        return (
          <OperationalDetailsStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setStep(ClaimStep.TuitionInfo)}
            onPrev={() => history.goBack()}
          />
        );
      case ClaimStep.TuitionInfo:
        return (
          <TuitionAndFeesStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setStep(ClaimStep.MealsInfo)}
            onPrev={() => history.goBack()}
          />
        );
      case ClaimStep.DocumentsInfo:
        return (
          <DocumentsStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onPrev={() => history.goBack()}
            onNext={() => setStep(ClaimStep.VirtualToursInfo)}
          />
        );
      case ClaimStep.ProgramsInfo:
        return (
          <ProgramsAndServicesStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onPrev={() => history.goBack()}
            onNext={() => setStep(ClaimStep.DocumentsInfo)}
          />
        );
      case ClaimStep.FeaturesInfo:
        return (
          <FeaturesAndAmenitiesStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setStep(ClaimStep.ProgramsInfo)}
            onPrev={() => history.goBack()}
          />
        );
      case ClaimStep.OverviewInfo:
        return (
          <OverviewStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setShouldSubmit(true)}
            onPrev={() => history.goBack()}
            loading={fetching}
          />
        );
      case ClaimStep.MealsInfo:
        return (
          <MealsStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setStep(ClaimStep.FeaturesInfo)}
            onPrev={() => history.goBack()}
          />
        );
      case ClaimStep.VirtualToursInfo:
        return (
          <VirtualTourStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setStep(ClaimStep.OverviewInfo)}
            onPrev={() => history.goBack()}
          />
        );
      case ClaimStep.Success:
        return (
          <ClaimSuccessStep
            showSchoolResponseSuccess={showSchoolResponseSuccess}
            onDone={closeModal}
          />
        );
    }
  };

  return (
    <Dialog
      open
      onClose={closeModal}
      data-test="claim-school-modal"
      tier={facility.subscriptionTier}
      title="School profile"
    >
      {showCloseWarning && (
        <CloseWarning
          onClose={() => setShowCloseWarning(false)}
          facilityView={facilityView}
        />
      )}
      <div className={classes.container}>{getStepView()}</div>
    </Dialog>
  );
};

export default track({
  page: "Facility Claim Full Flow",
})(ClaimForm);
