import React, { Fragment, useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import cloneDeep from "lodash/cloneDeep";

import Button from "@ui-kit/Button";
import useStyles from "../styles";
import Typography from "@ui-kit/Typography";
import { FACILITY_API, Models } from "@services/api";
import Select from "@ui-kit/Select";
import { Input } from "@ui-kit/InputFields";
import { required, email } from "@validators";
import { goToFormError } from "@helpers/goToFormError";
import { Spacer } from "@ui-kit/Spacer";
import { useUserStore } from "@store/UserStore";
import { ROLES } from "@constants/user-roles";
import {
  EditMediaUrlsView,
  processServerErrors,
} from "@pages/DeepDivePanel/EditMediaUrls";
import { processServerErrors as contactInfoProcessServerError } from "@components/EditFacilityInfoForm/view";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  onNext: () => void;
  setRole: (role: Models.UserRole) => void;
  defaultRole: Models.UserRole;
}

type FormFields = {
  role: Models.UserRole;
  email: string;
};

export const InitialStep: React.FC<IProps> = ({
  facilityView,
  defaultRole,
  setFacilityView,
  onNext,
  setRole,
}) => {
  const [view, setView] = useState(cloneDeep(facilityView));
  const [{ user }, { updateUser }] = useUserStore();
  const methods = useForm<FormFields>();
  const { control, handleSubmit, errors, trigger, formState, setError } =
    methods;
  const classes = useStyles();

  const submit = () => {
    void trigger().then((isValid) => {
      if (isValid) {
        void handleSubmit(async ({ role, email }) => {
          try {
            await FACILITY_API.facilitiesByIdUpdate({
              dryRun: true,
              facility: view,
              id: view.id,
            });

            setFacilityView({
              ...view,
              email,
            });
            setRole(role);

            if (user) {
              void updateUser({
                ...user,
                role,
              });
            }
            onNext();
          } catch (error) {
            const errors = error?.response?.data?.errors;

            if (
              !errors ||
              !(
                processServerErrors(errors, setError) ||
                contactInfoProcessServerError(errors, setError)
              )
            ) {
              throw error;
            }
          }
        })();
      } else {
        goToFormError();
      }
    });
  };

  return (
    <Fragment>
      <Typography required bolded>
        Your role
      </Typography>

      <Controller
        render={(controllerProps) => (
          <Select
            {...controllerProps}
            items={ROLES}
            labelProp="name"
            error={errors.role?.message}
          />
        )}
        rules={required()}
        defaultValue={defaultRole}
        name="role"
        control={control}
      />

      <Spacer size="medium" />

      <Typography required bolded>
        School's contact email
      </Typography>

      <Controller
        render={(controllerProps) => (
          <Input
            {...controllerProps}
            label="Contact email"
            type="email"
            error={errors.email?.message}
          />
        )}
        rules={{
          ...required(),
          ...email(),
        }}
        defaultValue={view.email || ""}
        name="email"
        control={control}
      />

      <Spacer size="medium" />

      <Typography bolded required>
        School links
      </Typography>

      <FormProvider {...methods}>
        <EditMediaUrlsView
          isClaim
          facilityView={view}
          setFacilityView={setView}
        />
      </FormProvider>

      <div className={classes.footer}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          data-test="next-btn"
          onClick={submit}
          loading={formState.isSubmitting}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};
