import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import cloneDeep from "lodash/cloneDeep";

import Button from "@ui-kit/Button";
import useStyles from "../styles";
import EditFacilityOverviewView, {
  FormFields,
} from "@components/FacilityOverviewForm";
import {
  BulletinBoardView,
  processServerErrors,
} from "@pages/EditFacilityBulletinBoard/view";
import { Models } from "@services/api";
import { goToFormError } from "@helpers/goToFormError";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import { FACILITY_API } from "@services/api";
import { useNotificationStore } from "@store/NotificationStore";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  onPrev: () => void;
  onNext: () => void;
  loading: boolean;
}

export const OverviewStep: React.FC<IProps> = ({
  facilityView,
  setFacilityView,
  onPrev,
  onNext,
  loading,
}) => {
  const [, { setNotification, setUnknownErrorNotification }] =
    useNotificationStore();
  const [view, setView] = useState(cloneDeep(facilityView));
  const methods = useForm<FormFields>();
  const { handleSubmit, trigger, setError, watch, formState } = methods;
  const classes = useStyles();

  const submit = () => {
    void trigger().then((isValid) => {
      if (isValid) {
        void handleSubmit(async () => {
          try {
            await FACILITY_API.facilitiesByIdUpdate({
              dryRun: true,
              facility: view,
              id: view.id,
            });
            setFacilityView(view);
            setTimeout(onNext, 0);
          } catch (error) {
            let errorHandled = false;

            const errors = error?.response?.data?.errors;

            if (errors) {
              if (processServerErrors(errors, setError, watch)) {
                errorHandled = true;
                goToFormError();
              } else {
                setNotification({
                  message: errors[0].title,
                  type: "error",
                });
                return;
              }
            }

            if (!errorHandled) {
              setUnknownErrorNotification();
              throw error;
            }
          }
        })();
      } else {
        goToFormError();
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <Typography variant="h4" align="center">
        About your school
      </Typography>

      <Spacer size="medium" />

      <EditFacilityOverviewView
        facilityView={view}
        setFacilityView={setView}
        isClaim
      />

      <Spacer size="medium" />

      <Typography variant="h4" align="center">
        School bulletin board
      </Typography>

      <Spacer size="medium" />

      <Typography>
        Tell families about ongoing promotions, special events and other
        newsworthy developments.
      </Typography>
      <Spacer size="medium" />

      <BulletinBoardView facilityView={view} setFacilityView={setView} />

      <div className={classes.footer}>
        <Button
          variant="outlined"
          color="primary"
          className="small-button"
          size="large"
          onClick={onPrev}
          data-test="prev-btn"
        >
          Back
        </Button>

        <Button
          variant="contained"
          color="primary"
          className="small-button"
          size="large"
          onClick={submit}
          data-test="finish-button"
          loading={formState.isSubmitting || loading}
        >
          Finish
        </Button>
      </div>
    </FormProvider>
  );
};
